.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'rois';
  src: url('fonts/Rois-Medium.eot'); /* IE9 Compat Modes */
  src: url('fonts/Rois-Medium.woff') format('woff'), /* Modern Browsers */
       url('fonts/Rois-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Rois-Medium.svg#Rois-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
::-moz-selection {
  color: white
}

::selection {
  color: white;
}

::-moz-selection {
background: black;
}

::selection {
    background: red;
}

img {
  width: 30%;
  display: block;
  margin: 50px;
  margin-right:auto;
  margin-left:auto;
  opacity: 100%;
}

.img-background {
  width: 100%;
  display: block;
  margin: 50px;
  margin-right:auto;
  margin-left:auto;
  opacity: 100%;
  top: "1000px"
}

.bg-mob {
  display: none;
}

body {
  background-color: #e9e9e9;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: crosshair;
  font-family: 'rois';
  height: 100vh;
  width: 100vw;
}


h1 {
  font-size: 6vw;
  line-height: 0.5;
  font-family: 'rois';
  text-transform: uppercase;
  background-color: rgb(255, 253, 253, .8) ;
  left: auto;
  box-shadow: 0px 0px 10px black;
  text-align: justify;
  display: block;
}

a {
  color: black;
  text-decoration: none;
  text-shadow: 0px 0px 5px white;
  filter: blur(0px);
  cursor: pointer;
}

a:hover {
  color: black;
  text-decoration: none;
  text-shadow: 0px 0px 15px white;
  filter: blur(0px);
  cursor: pointer;
}

.main {
  background-color: none;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -97;
}

.menu {
  position: relative;
  top: 0%;
  left: 0%;
  font-size: 30px;
  z-index: 10;
  margin: 10px;
  overflow: scroll;
  transition: 6s;
  box-shadow: 0px 0px 100px white;
}

.box{
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 100px;
  top: 50px;
  z-index: 1000;
  width: 85%;
  height: 94%;
  background: blue;
  background: linear-gradient(90deg, rgb(145, 143, 59) 50%, rgb(171, 170, 74) 80%, rgb(123, 125, 44) 80%);
  opacity: 95%;
  overflow: scroll;
  box-shadow: 0px 0px 10px black;
}

.contactBox{
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 40%;
  width: 80%;
  height: 35%;
  background: blue;
  background: linear-gradient(90deg, rgb(145, 143, 59) 50%, rgb(171, 170, 74) 80%, rgb(123, 125, 44) 80%);
  opacity: 95%;
  overflow: scroll;
  box-shadow: 0px 0px 10px black;
  text-align: left;
  font-size: 2.5vw;
  left:20%;
  margin-right:5px;
}

box:hover{
text-shadow: 0px 0px 0px black;
filter: blur(0px);
}

.music {
  position: relative;
  margin: 30px;
  font-size: 10px;
  background-color: #F5F5F5;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
  box-shadow: 5px 5px 10px black;
  margin-left:400px;
  margin-right:250px;
}

.video {
  box-shadow: 5px 5px 10px black;
  margin: 30px;
  margin-left:400px;
  margin-right:250px;
  width: 700;
  height: 500;
  background-color: black;
}

.hide1 {
  position: fixed;
  display: inline-block;
  top: 95.5%;
  left: 10%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-shadow: 0px 0px 5px black;
  cursor: pointer;
  font-size: 2vw;
}

.hide1:hover {
  color: black;
  text-decoration: none;
  text-shadow: 0px 0px 10px white;
  filter: blur(0px);
  cursor: pointer;
}

.text-header {
  position: relative;
  padding: 0px;
  margin-left:6px;
  margin-right:6px;
  line-height: 1.4;
  font-size: 1.5vw;
  text-align: justify;
  top: -20px;
}

.text-title {
  position: relative;
  padding: 0px;
  margin-left:6px;
  margin-right:6px;
  font-size: 1.5vw;
  text-align: justify;
  color: black;
}

.text-body {
  position: relative;
  top: -10px;
  padding: 0px;
  margin-left:400px;
  margin-right:250px;
  font-size: 1vw;
  text-align: justify;
  color: white;
  line-height: 1;
}

.logo {
  width: 50%;
  position: absolute;
  opacity: .25;
}

.logo:hover {
  opacity: .9;
  transform: scale(1.1);
  transition: 1s;
}

.video-background {
  position: fixed;
  background-color: red;
  background: black;
  opacity: 0.5;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
  transition: 1s;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {

img {
  width: 100%;
}

.box {
  left: 80px;
}

h1 {
  background-color: rgb(255, 253, 253, .4) ;
  font-size: 8vw;
  line-height: 2.7;
  box-shadow: 0px 0px 0px black;
}

.hide1 {
  font-size: 3vw;
}

.text-header {
  font-size: 4vw;
}

.text-title {
  margin-left:10px;
  margin-right:25px;
  font-size: 3vw;
  text-align: justify;
}

.text-body {
  text-align: justify;
  font-size: 2.5vw;
  margin-left:25px;
  margin-right:25px;
}

.hide1 {
  font-size: 4vw;
  color: #02F702;
}

.logo {
  width: 80%;
}

.music {
  margin-left:10px;
  margin-right:10px;
}

.video {
  margin-left:10px;
  margin-right:10px;
}

}

@media (min-aspect-ratio: 16/9) {
.video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
.video-foreground { width: 300%; left: -100%; }
}
